import { LoadingButton } from "@mui/lab";
import Select from "react-select";
import { LocalDialog } from "components";
import { ErrorMessage, Form, Formik } from "formik";
import { useLocalDialog } from "hooks";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as Yup from "yup";
import AccurateCustomerSelector from "views/finance/payment/AccurateCustomerSelector";
import { QueryObserverResult, useMutation, useQuery } from "react-query";
import IAdditionalPricePerBatchingPlant, {
	AccurateService,
	CommonService,
	CustomerService,
	HubService,
	OrderService
} from "api/axios";
import { InputNumberFormat } from "@react-input/number-format";
import axios from "axios";
import { useDebounce } from "use-debounce";

const labelStyle = {
	marginTop: "1em"
};

// options for developer
const Developers = [
	{
		valueStr: "N/A",
		labelStr: "N/A"
	},
	{
		valueStr: "Sinarmas Land",
		labelStr: "Sinarmas Land"
	},
	{
		valueStr: "Summarecon",
		labelStr: "Summarecon"
	},
	{
		valueStr: "Deltamas",
		labelStr: "Deltamas"
	},
	{
		valueStr: "CGST",
		labelStr: "CGST"
	},
	{
		valueStr: "Paramount",
		labelStr: "Paramount"
	},
	{
		valueStr: "Modernland",
		labelStr: "Modernland"
	},
	{
		valueStr: "Grand Wisata",
		labelStr: "Grand Wisata"
	},
	{
		valueStr: "Lippo Cikarang",
		labelStr: "Lippo Cikarang"
	}
];

// option for mobilization fee
const mobilizationFee = [
	{
		valueStr: "Iya",
		labelStr: "Iya"
	},
	{
		valueStr: "Tidak",
		labelStr: "Tidak"
	}
];

// option for mobilization option
const mobilizationOptions = [
	{
		valueStr: "Total Pengiriman di Hari H <4m3",
		labelStr: "Total Pengiriman di Hari H <4m3"
	},
	{
		valueStr: "Free Mobilisasi Berdasarkan Pengajuan Sales",
		labelStr: "Free Mobilisasi Berdasarkan Pengajuan Sales"
	},
	{
		valueStr: "N/A",
		labelStr: "N/A"
	}
];

// option for document type
const documentType = [
	{
		valueStr: "INVOICE",
		labelStr: "Faktur Pajak"
	},
	{
		valueStr: "EXPORT",
		labelStr: "Dokumen Ekspor (PEB)"
	},
	{
		valueStr: "DOCUMENT",
		labelStr: "Dokumen Dipersamakan FP"
	},
	{
		valueStr: "DIGUNGGUNG",
		labelStr: "Digunggung"
	}
];

// option for tax type
const taxType = [
	{ valueStr: "BKN_PEMUNGUT_PPN", labelStr: "01 - Bukan Pemungut PPN" },
	{
		valueStr: "BESARAN_TERTENTU",
		labelStr: "05 - Besaran Tertentu (Pasal 9A ayat (1) UU PPN)"
	},
	{ valueStr: "DPP_NILAILAIN", labelStr: "04 - DPP Nilai Lain" },
	{ valueStr: "EXPBKP_TDKWJD", labelStr: "14 - Ekspor BKP Tidak Berwujud" },
	{ valueStr: "EXPBKP_WJD", labelStr: "13 - Ekspor BKP Berwujud" },
	{ valueStr: "EXP_JKP", labelStr: "15 - Ekspor JKP" },
	{ valueStr: "PAJAK_DIDEEMED", labelStr: "05 - Pajak di Deemed" },
	{
		valueStr: "PEMUNGUT_BENDAHARA_PEMERINTAH",
		labelStr: "02 - Pemungut Bendaharawan Pemerintah"
	},
	{ valueStr: "PEMUNGUT_PPN", labelStr: "03 - Pemungut Selain Bendaharawan" },
	{
		valueStr: "PENYERAHAN_ASSET",
		labelStr: "09 - Penyerahan Aset (Pasal 16D UU PPN)"
	},
	{
		valueStr: "PENYERAHAN_LAIN",
		labelStr:
			"06 - Penyerahan Lainnya, termasuk penyerahan kepada turis asing dalam rangka VAT refund"
	},
	{ valueStr: "PPN_DIBEBASKAN", labelStr: "08 - PPN Dibebaskan" },
	{ valueStr: "PPN_TDK_DIPUNGUT", labelStr: "07 - PPN Tidak Dipungut" }
];

const pouringMethodOptions = [
	{
		valueStr: "Rigid",
		labelStr: "Rigid"
	},
	{
		valueStr: "Manual",
		labelStr: "Manual"
	},
	{
		valueStr: "Concrete Pump",
		labelStr: "Concrete Pump"
	}
];

// option for source lead
const sourcLeadOptions = [
	{
		valueStr: "Website",
		labelStr: "Website"
	},
	{
		valueStr: "Tokopedia",
		labelStr: "Tokopedia"
	},
	{
		valueStr: "Shopee",
		labelStr: "Shopee"
	},
	{
		valueStr: "Direct Sales",
		labelStr: "Direct Sales"
	},
	{
		valueStr: "WATI",
		labelStr: "WATI"
	},
	{
		valueStr: "Agent",
		labelStr: "Agent"
	}
];

const linkAccurateSchema = Yup.object().shape({
	paymentTerm: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Pembayaran harus dipilih"),
		labelStr: Yup.string().required("Syarat Pembayaran harus dipilih")
	}),
	needPriceApproval: Yup.object().shape({
		valueStr: Yup.string().required("Perlu Approval Harga harus dipilih"),
		labelStr: Yup.string().required("Perlu Approval Harga harus dipilih")
	}),
	documentCodeName: Yup.object().shape({
		valueStr: Yup.string().required("Jenis Dokumen harus dipilih"),
		labelStr: Yup.string().required("Jenis Dokumen harus dipilih")
	}),
	customerTaxType: Yup.object().shape({
		valueStr: Yup.string().required("Jenis Transaksi harus dipilih"),
		labelStr: Yup.string().required("Jenis Transaksi harus dipilih")
	}),
	sales: Yup.object().shape({
		valueStr: Yup.string().required("Sales harus dipilih"),
		labelStr: Yup.string().required("Sales harus dipilih")
	}),
	customerDeveloper: Yup.object().shape({
		valueStr: Yup.string().required("Developer harus dipilih"),
		labelStr: Yup.string().required("Developer harus dipilih")
	}),
	customerLimitAge: Yup.boolean(),
	customerLimitAgeValue: Yup.number().when("customerLimitAge", {
		is: true,
		then: (schema) => schema.required("Hari harus diisi")
	}),
	customerLimitAmount: Yup.boolean(),
	customerLimitAmountValue: Yup.string().when("customerLimitAmount", {
		is: true,
		then: (schema) => schema.required("Jumlah harus diisi")
	}),
	mobilizationFee: Yup.object().shape({
		valueStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		),
		labelStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		)
	}),
	mobilizationOption: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Mobilisasi harus dipilih"),
		labelStr: Yup.string().required("Syarat Mobilisasi harus dipilih")
	}),
	accurateTaxable: Yup.boolean(),
	accurateInclusiveTax: Yup.boolean(),
	customerAccurate: Yup.object().required("Pelanggan Accurate harus diisi")
});

export default function LinkQuotation({
	accurateData,
	customerId,
	shippingAddress,
	batchingPlant,
	requestedProducts,
	projectSelected,
	refetch
}: {
	accurateData?: AccurateDataCustomer;
	customerId?: string;
	requestedProducts?: QuotationProductModel[];
	shippingAddress?: any;
	batchingPlant?: any;
	projectSelected?: string | null;
	refetch: () => Promise<
		QueryObserverResult<{ data: ApiQuotation }, ApiError>
	>;
}) {
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	const params = useParams();
	const id = params?.id;

	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);

	// accurate customer search debounce
	const [searchCustomer, setSearchCustomer] = React.useState("");
	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [customerSelected, setCustomerSelected] =
		React.useState<ApiResponsePlain<CustomerDetailSelected> | null>();

	// sales search debounce
	const [nameSales, setNameSales] = React.useState<string>("");
	const [searchSalesQ] = useDebounce(nameSales, 500);

	// distance
	const [projectDistance, setProjectDistance] = React.useState<number>();

	// accurate disable checking
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	// get one customer bod
	useQuery<ApiResponsePlain<CustomerDetailSelected>, ApiError>(
		["customer-detail", customerId],
		async () => {
			return await CommonService.getOneCustomer(customerId as string);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setCustomerSelected(res);
			},
			onError: (error) => {
				console.log(error);
			}
		}
	);

	// get all customer accurate
	const { data: customersAccurate, isLoading: customerListLoading } =
		useQuery<ApiResponsePlain<CustomerAccurate[]>>(
			["query-customer-accurate", searchCustomerDebounced],
			async () => {
				return await HubService.getCustomersAccurate({
					keywords: searchCustomerDebounced || ""
				});
			},
			{
				onError(err: any) {
					dispatch(
						openAlert({
							body: "message",
							color: "danger"
						})
					);
				},
				enabled: true
			}
		);

	const lat = shippingAddress.lat;
	const lon = shippingAddress.lon;
	const distance = batchingPlant.name;

	// get distance between project and bp
	useQuery<any, ApiError>(
		["distance-project"],
		async () => {
			return await CustomerService.getAdditionalPricePerBatchingPlant(
				projectSelected as string
			);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const batchingPlantPrice = res.find(
					(plant: IAdditionalPricePerBatchingPlant) => {
						return plant.name === batchingPlant?.name;
					}
				);

				setProjectDistance(batchingPlantPrice?.distance || null);
			},
			onError: (error) => {
				console.log(error);
			}
		}
	);

	// get one customer accurate data
	const { data: detailAccurateCustomer } = useQuery<
		ApiResponsePlain<CustomerAccurate>
	>(
		[
			"query-customer-accurate-details",
			customerSelected?.data?.customerAccurateId
		],
		async () => {
			return await HubService.getDetailCustomerAccurate(
				customerSelected?.data?.customerAccurateId.toString() ?? ""
			);
		},
		{
			enabled: !!customerSelected?.data?.customerAccurateId
		}
	);

	const defaultAccurateCustomer = React.useMemo(() => {
		if (!detailAccurateCustomer || !detailAccurateCustomer.data) {
			return undefined;
		}

		return {
			mobilePhone: detailAccurateCustomer.data.mobilePhone,
			name: detailAccurateCustomer.data.name,
			id: detailAccurateCustomer.data.id,
			whatsappNo: detailAccurateCustomer.data.whatsappNo,
			customerNo: detailAccurateCustomer.data.customerNo,
			email: detailAccurateCustomer.data.email
		} as CustomerAccurate;
	}, [detailAccurateCustomer]);

	// get all paymentTerms
	const { data: paymentTerms, isLoading: paymentTermsLoading } = useQuery<
		ApiResponsePlain<PaymentTerm[]>,
		ApiError
	>(
		["query-accurate-payment-term"],
		async () => {
			return AccurateService.getAccuratePaymentTermApi({});
		},
		{
			enabled: true
		}
	);

	const paymentTermOptions = React.useMemo(() => {
		if (paymentTerms?.data) {
			return paymentTerms?.data.map((el) => {
				return {
					valueStr: el.name,
					labelStr:
						el.name +
						(el.dueDescription ? `- (${el.dueDescription})` : "")
				};
			});
		}
		return [];
	}, [paymentTerms]);

	// get all sales bod
	const { data: salesPeople } = useQuery<IUsers, ApiError>(
		["query-filter-sales", searchSalesQ],
		async () => {
			return await CommonService.getUsers(1, 99, "SALES", {
				search: searchSalesQ
			});
		}
	);

	const optionSales = React.useMemo(() => {
		if (salesPeople?.data?.data?.length) {
			const data = salesPeople?.data?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.fullName
				};
			});

			return data;
		}
		return [];
	}, [salesPeople]);

	// get all sales accurate
	const { data: accurateEmployee } = useQuery<
		ApiResponsePlain<AccurateResponse<AccurateEmployee[]>>,
		ApiError
	>(
		["query-accurate-employee", accurateData?.salesmanNumber],
		async () => {
			return AccurateService.getAccurateEmployeeApi({
				keywords: accurateData?.salesmanNumber
			});
		},
		{
			enabled: true
		}
	);

	const employeeOptions = React.useMemo(() => {
		if (
			accurateEmployee?.data?.d &&
			Array.isArray(accurateEmployee?.data?.d)
		) {
			return accurateEmployee?.data?.d?.map((el) => {
				return {
					valueStr: el.number,
					labelStr: el.name
				};
			});
		}
		return [];
	}, [accurateEmployee]);

	const { mutateAsync: updateQuotation, isLoading: loadingPut } = useMutation(
		async (payload: any) => {
			return await OrderService.updateQuotationAccurate(
				id as string,
				payload
			);
		},
		{
			onSuccess: () => {
				refetch();
				localDialog.onClose();
			}
		}
	);

	const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true
		}
	);

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	const mappedSkuSpec = React.useMemo(() => {
		if (skuSpec?.data && skuOrder?.data) {
			return skuOrder?.data.map((order: string) => {
				const spec = skuSpec.data.find((el) => el.name === order);
				if (spec) {
					const values = Object.keys(spec.values).map((key) => {
						return {
							value: key,
							label: spec.values[key] || "",
							spec: spec.name
						};
					});

					return {
						name: spec?.displayName || "",
						option: values,
						key: spec.name
					};
				} else {
					return {
						name: "",
						option: [],
						key: ""
					};
				}
			});
		}
		return [];
	}, [skuSpec, skuOrder]);

	const location = React.useMemo(() => {
		const locationOptions = mappedSkuSpec.find((el) => {
			return el.key === "location";
		});
		return locationOptions?.option.find((el) => {
			return (
				el.label.toLowerCase() ===
				batchingPlant?.name
					?.split("-")[1]
					?.replace(/[\s_]+/g, "")
					?.toLowerCase()
			);
		});
	}, [mappedSkuSpec, batchingPlant]);

	const quality = {
		FC: "F",
		K: "K",
		FS: "S",
		Class: "C"
	};

	const cementitious = {
		FA: "A",
		NFA: "B"
	};

	return (
		<Fragment>
			<Button
				size="sm"
				color="success"
				className="confirm-button approve-button"
				onClick={() => {
					localDialog.onOpen();
				}}
			>
				Link
			</Button>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Link SPH to Accurate"
				onClose={() => {
					localDialog.onClose();
				}}
				fullScreen
			>
				<Formik
					initialValues={{
						customerAccurate: null as CustomerAccurate | null,
						customerDeveloper:
							Developers.find(
								(dev) =>
									dev.labelStr === accurateData?.charField4
							) || (Developers[0] as OptionLine | undefined),
						paymentTerm:
							paymentTermOptions.find(
								(el) => el.valueStr === accurateData?.termName
							) ||
							(paymentTermOptions[0] as OptionLine | undefined),
						salesmanNumber:
							employeeOptions.find(
								(empl) =>
									empl.valueStr ===
									accurateData?.salesmanNumber
							) || (null as OptionLine | null),
						needPriceApproval: {
							labelStr: "Iya",
							valueStr: "Iya"
						},
						mobilizationFee: mobilizationFee.find(
							(tax) => tax.valueStr === accurateData?.charField2
						) || {
							valueStr: "",
							labelStr: ""
						},
						mobilizationOption: mobilizationOptions.find(
							(tax) => tax.valueStr === accurateData?.charField3
						) || {
							valueStr: "",
							labelStr: ""
						},
						accurateTaxable: false,
						accurateInclusiveTax: false,
						sales: {
							labelStr: "",
							valueStr: ""
						},
						documentCodeName:
							documentType.find(
								(doc) =>
									doc.valueStr === accurateData?.documentCode
							) || (documentType[0] as OptionLine | undefined),
						customerTaxType:
							taxType.find(
								(tax) =>
									tax.valueStr ===
									accurateData?.customerTaxType
							) || (taxType[0] as OptionLine | undefined),
						leadSource:
							sourcLeadOptions.find(
								(lead) =>
									lead.valueStr === accurateData?.charField1
							) || (null as OptionLine | null),
						requestedProducts:
							requestedProducts?.map((product) => {
								const splittedName =
									product.displayName.split(" ");
								const productSpec = splittedName[0]?.split("-");
								const productQuality =
									productSpec?.[0] as keyof typeof quality;
								const productStrength = productSpec?.[1];

								const productCategory =
									product.Product?.Category?.name;

								const skuSpec =
									splittedName[1] !== "FA" &&
									splittedName[1] !== "NFA"
										? splittedName[1]
										: undefined;
								const productType = mappedSkuSpec.find(
									(sku) => {
										return sku.key === "product-type";
									}
								);
								const type = productType?.option.find((el) => {
									if (!skuSpec) {
										return el.label.includes("Standard");
									}

									return el.label.includes(skuSpec);
								});

								const spec = {
									"concrete-grade-a":
										quality[productQuality] || "",
									location: location?.value || "",
									"slump-b": "2",
									"pricing-range": "S",
									"concrete-grade-b":
										productStrength?.padStart(3, "0") || "",
									"cementitious-component":
										cementitious[
											productCategory as "FA" | "NFA"
										] || "",
									"aggregate-combination": "A",
									"slump-a": "12",
									"casted-element": "",
									"product-type": type?.value || "A",
									"pouring-method": "B"
								};

								return {
									offeringPrice: product.offeringPrice,
									name: product.name,
									displayName: product.displayName,
									quantity: product.quantity,
									// pouringMethod:  product.pouringMethod,
									pouringMethod: pouringMethodOptions.find(
										(op) =>
											op.labelStr ===
											product.pouringMethod
									),
									unit: product.unit,
									labelStr: product.displayName,
									valueStr: product.id,
									productId: product.Product.id,
									requestedProductId: product.id,
									calcPrice:
										product.offeringPrice *
										product.quantity,
									specifications: product?.specifications
										? product.specifications
										: spec,
									skuId: product?.skuId || "",
									sku: product?.SKU?.sku || ""
								};
							}) || [],
						customerLimitAge:
							accurateData?.customerLimitAge || false, // customerLimitAge value
						customerLimitAgeValue:
							accurateData?.customerLimitAgeValue || "", // customerLimitAgeValue value
						customerLimitAmount:
							accurateData?.customerLimitAmount || false, // customerLimitAmount value
						customerLimitAmountValue:
							accurateData?.customerLimitAmountValue?.toString() ||
							""
					}}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true);

						try {
							const payload = {
								accurateData: {
									salesmanNumber:
										values.salesmanNumber?.valueStr,
									// customerNo: values.accurateNumber,
									termName: values.paymentTerm?.valueStr,
									documentCode:
										values.documentCodeName?.valueStr,
									customerTaxType:
										values.customerTaxType?.valueStr,
									customerLimitAge: values.customerLimitAge,
									customerLimitAgeValue:
										typeof values.customerLimitAgeValue ===
										"number"
											? values.customerLimitAgeValue
											: undefined,
									customerLimitAmount:
										values.customerLimitAmount,
									customerLimitAmountValue: parseFloat(
										values.customerLimitAmountValue
											.replace(",00", "")
											.replace(/\D+/g, "")
									),
									charField1: values.leadSource?.valueStr,
									charField2:
										values.mobilizationFee?.valueStr,
									charField3:
										values.mobilizationOption?.valueStr,
									charField4:
										values.customerDeveloper?.valueStr
								},
								requestedProducts: values.requestedProducts.map(
									(prod: any) => {
										return {
											id: prod.requestedProductId,
											offeringPrice: prod.offeringPrice,
											quantity: prod.quantity,
											specifications: prod.specifications,
											sku: prod.skuId
												? prod.sku
												: prod.sku + "00",
											productId: prod.productId,
											skuId: prod.skuId
										};
									}
								),
								accuratePaymentTerm:
									values.paymentTerm?.valueStr,
								accurateNeedPriceApproval:
									values.needPriceApproval.valueStr,
								accurateTaxable: values.accurateTaxable,
								accurateInclusiveTax:
									values.accurateInclusiveTax,
								customerId: values.customerAccurate?.id,
								accurateProjectDistance: projectDistance
							};

							const res = await updateQuotation(payload);

							dispatch(
								openAlert({
									body: res?.message,
									color: "success"
								})
							);
						} catch (error) {
							console.log(error, "error link quotation");

							let errorMessage =
								"Gagal Link Quotation dengan Accurate";

							if (axios.isAxiosError(error)) {
								const accurateError =
									error?.response?.data?.data?.error?.data?.errors?.join(
										" "
									);
								errorMessage =
									accurateError ||
									error?.response?.data?.data?.message ||
									error?.response?.data?.data?.error
										?.message ||
									"Unknown error";
							}

							dispatch(
								openAlert({
									body: errorMessage,
									color: "danger"
								})
							);
						}
					}}
					validationSchema={linkAccurateSchema}
				>
					{({
						handleChange,
						values,
						errors,
						setFieldValue,
						isSubmitting
					}) => (
						<Form>
							<AccurateCustomerSelector
								customers={customersAccurate?.data || []}
								setSearchCustomer={setSearchCustomer}
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								isLoading={customerListLoading}
								defaultAccurateCustomer={
									defaultAccurateCustomer
								}
								isDisabled={
									customerSelected?.data &&
									!defaultAccurateCustomer?.id
										? false
										: true
								}
							/>

							{/* Developer dropdown */}
							<Label style={labelStyle}>Developer *</Label>
							<Select
								name="customerDeveloper"
								value={values.customerDeveloper}
								options={Developers}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("customerDeveloper", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/>
							<ErrorMessage
								name={"customerDeveloper.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								Pilih Syarat Pembayaran *
							</Label>
							{paymentTermOptions.length ? (
								<Select
									name="paymentTerm"
									value={values.paymentTerm}
									options={paymentTermOptions}
									getOptionLabel={(option) => {
										if (option) {
											return option.labelStr;
										}
										return "";
									}}
									getOptionValue={(option) => {
										if (option) {
											return option.valueStr as string;
										}
										return "";
									}}
									onChange={(change) => {
										if (change) {
											setFieldValue("paymentTerm", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
									defaultValue={
										paymentTermOptions.find(
											(el) =>
												el.valueStr ===
												accurateData?.termName
										) || undefined
									}
								/>
							) : null}

							<ErrorMessage
								name={"paymentTerm.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle} htmlFor="searchSales">
								Sales *
							</Label>
							<Select
								onInputChange={(text) => {
									setNameSales(text);
								}}
								onChange={(change) => {
									if (change) {
										setFieldValue("sales", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
										values.sales.labelStr =
											change?.labelStr as string;
										values.sales.valueStr =
											change?.valueStr as string;
									}
								}}
								name="sales"
								value={values.sales}
								options={optionSales}
								getOptionLabel={(option: options) =>
									option.labelStr as string
								}
								getOptionValue={(option: options) =>
									option.labelStr as string
								}
								noOptionsMessage={() => "Ketik Nama Sales"}
								isDisabled={false}
							/>
							<ErrorMessage
								name={"sales.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>Sumber Lead</Label>
							<Select
								name="leadSource"
								value={values.leadSource}
								options={sourcLeadOptions}
								getOptionLabel={(option) => {
									if (option) {
										return option.labelStr;
									}
									return "";
								}}
								getOptionValue={(option) => {
									if (option) {
										return option.valueStr as string;
									}
									return "";
								}}
								onChange={(change) => {
									if (change) {
										setFieldValue("leadSource", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/>
							<ErrorMessage
								name={"leadSource.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								Default Penjual (Accurate)
							</Label>
							<Select
								name="salesmanNumber"
								value={values.salesmanNumber}
								options={employeeOptions}
								getOptionLabel={(option) => {
									if (option) {
										return (
											option.labelStr +
											` - ${option.valueStr}`
										);
									}
									return "";
								}}
								getOptionValue={(option) => {
									if (option) {
										return option.valueStr as string;
									}
									return "";
								}}
								onChange={(change) => {
									if (change) {
										setFieldValue("salesmanNumber", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
								defaultValue={employeeOptions.find(
									(el) =>
										el.valueStr ===
										accurateData?.salesmanNumber
								)}
							/>
							<ErrorMessage
								name={"salesmanNumber.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								Perlu Approval Harga *
							</Label>
							<Select
								name="needPriceApproval"
								value={values.needPriceApproval}
								options={[
									{
										valueStr: "Iya",
										labelStr: "Iya"
									},
									{
										valueStr: "Tidak",
										labelStr: "Tidak"
									}
								]}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("needPriceApproval", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/>
							<ErrorMessage
								name={"needPriceApproval.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								Dikenakan Biaya Mobilisasi? *
							</Label>
							<Select
								name="mobilizationFee"
								value={values.mobilizationFee}
								options={mobilizationFee}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("mobilizationFee", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
								// isDisabled={
								// 	values.customer?.valueStr ? false : true
								// }
							/>
							<ErrorMessage
								name={"mobilizationFee.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								Syarat Mobilisasi *
							</Label>
							<Select
								name="mobilizationOption"
								value={values.mobilizationOption}
								options={mobilizationOptions}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("mobilizationOption", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
								// isDisabled={
								// 	values.customer?.valueStr ? false : true
								// }
							/>
							<ErrorMessage
								name={"mobilizationOption.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>Jenis Dokumen *</Label>
							<Select
								name="documentCodeName"
								value={values.documentCodeName}
								options={documentType}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("documentCodeName", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
								defaultValue={documentType.find(
									(el) =>
										el.valueStr ===
										accurateData?.documentCode
								)}
							/>
							<ErrorMessage
								name={"documentCodeName.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>Jenis Transaksi *</Label>
							<Select
								name="customerTaxType"
								value={values.customerTaxType}
								options={taxType}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("customerTaxType", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/>
							<ErrorMessage
								name={"customerTaxType.labelStr"}
								component="div"
								className="error-msg"
							/>

							<Label style={labelStyle}>
								<b>Info Pajak</b>{" "}
							</Label>
							{/* Sub judul */}
							{/* Info Pajak */}
							<div
								style={{
									display: "flex",
									flexDirection: "column"
								}}
							>
								{/* Contents */}
								<div
									style={{
										display: "flex",
										flexDirection: "column"
									}}
								>
									<div
										style={{
											marginTop: "1rem"
										}}
									>
										<div
											style={{
												display: "flex",
												gap: "2rem",
												marginLeft: "1.3rem"
											}}
										>
											<Label check>
												<Input
													type="checkbox"
													checked={
														values.accurateTaxable
													}
													name="accurateTaxable"
													onChange={handleChange}
												/>{" "}
												Kena Pajak{" "}
											</Label>

											<Label check>
												<Input
													type="checkbox"
													checked={
														values.accurateInclusiveTax
													}
													name="accurateInclusiveTax"
													onChange={handleChange}
												/>{" "}
												Total Termasuk Pajak
											</Label>
										</div>
									</div>
								</div>
							</div>

							{/* Faktur */}
							<Label style={labelStyle}>
								<b>Pembatasan Piutang Pelanggan</b>
							</Label>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1em",
									justifyContent: "space-between",
									marginBottom: "1em"
								}}
							>
								<div className="d-flex" style={{ gap: "1em" }}>
									<input
										type="checkbox"
										name="customerLimitAge"
										id="customerLimitAge"
										checked={values.customerLimitAge}
										onChange={handleChange}
									/>

									<div>
										<Label htmlFor="customerLimitAge">
											Jika ada faktur berumur lebih dari
										</Label>
										<ErrorMessage
											name={"customerLimitAgeValue"}
											component="div"
											className="error-msg"
										/>
									</div>
								</div>
								<div
									className="d-flex"
									style={{
										width: "35%",
										gap: "1em",
										alignItems: "center"
									}}
								>
									{values.customerLimitAge ? (
										<>
											<Input
												type="number"
												name="customerLimitAgeValue"
												onChange={handleChange}
												value={
													values.customerLimitAgeValue
												}
											/>
										</>
									) : (
										<div
											style={{
												backgroundColor: "lightgrey",
												textDecoration: "line-through"
											}}
											className="form-control"
										>
											{values.customerLimitAgeValue}
										</div>
									)}
									<p>Hari</p>
								</div>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1em",
									justifyContent: "space-between"
								}}
							>
								<div className="d-flex" style={{ gap: "1em" }}>
									<input
										type="checkbox"
										name="customerLimitAmount"
										id="customerLimitAmount"
										checked={values.customerLimitAmount}
										onChange={handleChange}
									/>
									<div>
										<Label htmlFor="customerLimitAmount">
											Jika total piutang & pesanan
											melebihi
										</Label>
										<ErrorMessage
											name={"customerLimitAmountValue"}
											component="div"
											className="error-msg"
										/>
									</div>
								</div>
								{values.customerLimitAmount ? (
									<>
										{/* <Input
												type="number"
												style={{
													width: "35%"
												}}
												name="customerLimitAmountValue"
												onChange={handleChange}
												value={
													values.customerLimitAmountValue
												}
											/> */}
										<InputNumberFormat
											locales="id-ID"
											format="currency"
											currency="IDR"
											name="customerLimitAmountValue"
											onChange={handleChange}
											value={
												values.customerLimitAmountValue
											}
											className="form-control"
											style={{
												width: "35%"
											}}
										/>
									</>
								) : (
									<div
										style={{
											width: "35%",
											backgroundColor: "lightgrey",
											textDecoration: "line-through"
										}}
										className="form-control"
									>
										{values.customerLimitAmountValue}
									</div>
								)}
							</div>

							{/* Distance */}
							<div style={{ marginTop: "1rem" }}>
								<span>
									Jarak dari BP ke Proyek:{" "}
									<b>{projectDistance}</b> KM
								</span>
							</div>

							<div
								className="modal-custom-footer"
								style={{ marginTop: "1rem" }}
							>
								<LoadingButton
									color="error"
									disabled={isSubmitting}
									onClick={() => {
										localDialog.onClose();
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Iya
									</span>
								</LoadingButton>
							</div>
						</Form>
					)}
				</Formik>
			</LocalDialog>
		</Fragment>
	);
}
