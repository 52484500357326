import BCard from "components/B/card";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalDialog } from "hooks";
import { CommonService, HubService, OrderService } from "api/axios";
import { LocalDialog, Spacer } from "components";
import { Button, Card, CardBody, Table } from "reactstrap";
import { getPriceFormatted } from "helper/product";
import { ChevronLeft } from "react-bootstrap-icons";
import { formatComma, handleResponseFile } from "helper";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import UpdateRequestedProduct from "./updateRequestedProduct";
import BForm from "components/B/form";
import ApproveQuotation from "./ApproveQuotation";
import UpdateQuotation from "./UpdateQuotation";
import LinkQuotation from "./LinkToAccurate";
import AccurateChip from "components/B/AccurateChip";

const QuotationDetail = () => {
	const { localDialog, setLocalDialog } = useLocalDialog();
	const { localDialog: sphNumberDialog, setLocalDialog: setSphNumberDialog } =
		useLocalDialog();
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [sphNumber, setSphNumber] = React.useState<string>("");
	const [products, setProducts] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");
	const [uploadFile, setUploadFile] = React.useState<File>();
	const [isEditProduct, setIsEditProduct] = React.useState(false);
	const [isEditBP, setIsEditBP] = React.useState({ isOpen: false });
	const [isReject, setIsReject] = React.useState({ isOpen: false });
	const [isLoadingRejectQuo, setIsLoadingRejectQuo] = React.useState(false);
	const [quoLetterId, setQuoLetterId] = React.useState<string>("");
	const [selectedBp, setSelectedBp] = React.useState<string>("");

	const dispatch = useAppDispatch();

	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);

	// accurate disable checking
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const { isLoading, refetch, data } = useQuery<
		{ data: ApiQuotation },
		ApiError
	>(
		["query-quotations"],
		async () => {
			return await OrderService.getOneQuotation(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany =
					!!res?.data?.QuotationRequest?.Project?.companyName;
				const isCustomer =
					!!res?.data?.QuotationRequest?.Project?.Customer;
				setSphNumber(res?.data?.number);
				setStatus(res?.data?.status);

				setQuoLetterId(res?.data?.id);

				try {
					const products =
						res?.data?.QuotationRequest?.RequestedProducts;
					setProducts(products);
				} catch (e) {
					//
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.QuotationRequest?.Project?.Customer
									?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.QuotationRequest?.Project
											?.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.QuotationRequest?.Project
											?.Customer?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.QuotationRequest?.Project?.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.data?.QuotationRequest?.Project
										?.Company?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.QuotationRequest?.Project
									?.mainPic?.name,
								position:
									res?.data?.QuotationRequest?.Project
										?.mainPic?.position,
								phone: res?.data?.QuotationRequest?.Project
									?.mainPic?.phone,
								email: res?.data?.QuotationRequest?.Project
									?.mainPic?.email
							}
						},
						res?.data?.QuotationRequest?.Project?.Pics.length >
							1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.QuotationRequest?.Project
									?.Pics,
								mainPIC:
									res?.data?.QuotationRequest?.Project
										?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.QuotationRequest?.Project
									?.BillingAddress?.line1,
								lat: res?.data?.QuotationRequest?.Project
									?.BillingAddress?.lat,
								lon: res?.data?.QuotationRequest?.Project
									?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.QuotationRequest?.Project
									?.ShippingAddress?.line1,
								lat: res?.data?.QuotationRequest?.Project
									?.ShippingAddress?.lat,
								lon: res?.data?.QuotationRequest?.Project
									?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					const files =
						res?.data?.QuotationLetterFiles?.map((v, idx) => {
							return {
								name: v?.type,
								type: "file",
								data: v?.File
							};
						}) ?? [];
					setFiles(files);
				} catch (e) {
					//
				}

				try {
					const totalDeliveryPrice =
						res?.data?.QuotationRequest?.totalDeliveryPrice;
					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data:
								res?.data?.QuotationRequest?.Project?.User
									?.fullName ?? "-"
						},
						{
							name: "Catatan",
							type: "text",
							data: res?.data?.notes ?? "-"
						},
						{
							name: "Tol",
							type: "boolean",
							data: res?.data?.QuotationRequest?.viaTol
						},
						{
							name: "Tipe Pembayaran",
							type: "chips",
							data: [
								res?.data?.QuotationRequest?.paymentType ==
								"CREDIT"
									? {
											text: "Credit",
											color: "green",
											textColor: "white"
									  }
									: {
											text: "Cash",
											color: "blue",
											textColor: "white"
									  }
							]
						},
						res?.data?.expiryDate
							? {
									name: "Tanggal Kadaluwarsa",
									type: "date",
									data: new Date(res?.data?.expiryDate)
							  }
							: null,
						res?.data?.QuotationRequest?.distance
							? {
									name: "Jarak",
									data: `${res?.data?.QuotationRequest?.distance} km`
							  }
							: null,
						{
							type: "line"
						},
						totalDeliveryPrice
							? {
									name: "Sub total",
									type: "price",
									data: res?.data?.QuotationRequest
										?.totalPrice
							  }
							: null,
						totalDeliveryPrice
							? {
									name: "Pengiriman (Kuantitas dibawah 7m3)",
									type: "price",
									data: totalDeliveryPrice
							  }
							: null,
						{
							name: totalDeliveryPrice
								? "Jumlah Keseluruhan"
								: "Jumlah",
							type: "price",
							data:
								res?.data?.QuotationRequest?.totalPrice +
								(totalDeliveryPrice ?? 0),
							format: "bold"
						}
					];
					setSummary(summary);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: batchingPlant } = useQuery<any, ApiError>(
		["query-batchingPlant", true],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true
		}
	);

	const listBatchingPlant = React.useMemo(() => {
		if (batchingPlant?.data?.data?.length) {
			const data = batchingPlant?.data.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [batchingPlant]);

	const forms: ElementForm[] = React.useMemo(() => {
		const initialBp = data?.data?.QuotationRequest?.BatchingPlant?.id;
		const _form: ElementForm[] = [
			{
				label: "Daftar Batching Plant",
				type: "dropdown",
				value: initialBp,
				onChange: (event: { valueStr: string; labelStr: string }) => {
					const selectedBP = event?.valueStr;

					setSelectedBp(selectedBP);
				},
				// onChange: (selectedValue: any) => {
				// 	const selectedBatchingPlantId = selectedValue?.valueStr;

				// 	console.log(
				// 		"Selected Batching Plant ID:",
				// 		selectedBatchingPlantId
				// 	);
				// 	setSelectedBp(selectedBatchingPlantId);
				// },
				placeholder: "Pilih Batching Plant",
				options: listBatchingPlant,
				required: true
			}
		];
		return _form;
	}, [listBatchingPlant]);

	const { mutate, isLoading: loadingEditBP } = useMutation(
		async (data: updateQuotationBP) => {
			return await OrderService.editQuotationBP(id as string, data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
				dispatch(
					openAlert({
						body: "Berhasil Ubah Batching Plant",
						color: "success"
					})
				);
				setIsEditBP({ isOpen: false });
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const submitBp = () => {
		const payload = {
			quotationLetterId: quoLetterId,
			batchingPlantId: selectedBp
		};

		mutate(payload);
	};

	const { mutateAsync: uploadFileAws, isLoading: loadingUpload } =
		useMutation<ApiResponseFiles, ApiError, FormData, ApiError>(
			async (_files: FormData) => {
				return await CommonService.uploadFile(_files);
			}
		);

	const { mutateAsync: putFile, isLoading: loadingPut } = useMutation(
		async (files: QuotationFile[]) => {
			return await OrderService.putQuotation(id as string, files);
		}
	);
	const { mutate: fullPayment, isLoading: isLoadingFullPayment } =
		useMutation(
			async () => {
				return await OrderService.patchFullPayment(id as string);
			},
			{
				onSuccess: () => {
					refetch();
				}
			}
		);

	const { mutateAsync: pdfGenerate, isLoading: loadingPdf } = useMutation(
		async () => {
			return await HubService.pdfGenQuotation(id as string);
		},
		{
			onSuccess: () => {
				refetch();
			}
		}
	);

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	async function submitHandler() {
		try {
			const _file = new FormData();
			_file.append("photos", uploadFile as File);
			_file.append("name", "SIGNED_SO");
			const response = await uploadFileAws(_file);
			const data = handleResponseFile(response.data, [
				uploadFile as File
			]);
			const payload = data.map((el) => ({
				...el,
				type: "LETTER"
			}));
			await putFile(payload);
			setUploadFile(undefined);
			refetch();
			localDialog.onClose();
			dispatch(
				openAlert({
					body: "Berhasil Upload SPH!",
					color: "success"
				})
			);
		} catch (error) {
			dispatch(
				openAlert({
					body: "Gagal upload SPH",
					color: "danger"
				})
			);
		}
	}

	// reject sph
	async function rejectQuotation() {
		setIsLoadingRejectQuo(true);

		try {
			await OrderService.rejectQuotation(id as string);
			refetch();
			dispatch(
				openAlert({
					body: "Berhasil Membatalkan Surat Penawaran Harga",
					color: "success"
				})
			);
			setIsReject({ isOpen: false });
			setIsLoadingRejectQuo(false);
		} catch (error) {
			setIsLoadingRejectQuo(false);
			dispatch(
				openAlert({
					body: "Gagal Membatalkan SPH",
					color: "danger"
				})
			);
		}
	}

	const castedElement = skuSpec?.data?.find(
		(spec) => spec.name === "casted-element"
	);
	const productType = skuSpec?.data?.find(
		(spec) => spec.name === "product-type"
	);
	// const aggregateCombination = skuSpec?.data?.find((spec) => spec.name === "aggregate-combination");
	const cementitiousComposition = skuSpec?.data?.find(
		(spec) => spec.name === "cementitious-component"
	);

	return (
		<div className="p-4 pt-6">
			{isEditProduct ? (
				<UpdateRequestedProduct
					isOpen={isEditProduct}
					setIsOpen={setIsEditProduct}
					refetch={refetch}
				/>
			) : null}

			<UpdateQuotation
				localDialog={sphNumberDialog}
				setLocalDialog={setSphNumberDialog}
				number={sphNumber}
				refetch={refetch}
			/>

			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{sphNumber}</h1>

				{data?.data?.status !== "DRAFT" &&
				data?.data?.status !== "CANCELLED" ? (
					<div className="d-flex align-items-center">
						<Button
							onClick={() => {
								sphNumberDialog.onOpen();
							}}
							size="sm"
							color="warning"
							className="confirm-button approve-button"
							style={{ marginLeft: "0.5rem" }}
						>
							Ubah Nomor SPH
						</Button>

						{/* : null} */}
					</div>
				) : null}

				<div className="mr-2">
					<BStatus className="ml-2">{status}</BStatus>

					{data?.data.QuotationRequest.isFullPayment &&
					data?.data.status !== "CANCELLED" ? (
						<BStatus className="ml-2">Full Payment</BStatus>
					) : null}

					{!data?.data.QuotationRequest.isFullPayment &&
					data?.data.status !== "CANCELLED" ? (
						<Button
							color="primary"
							size="sm"
							variant="contained"
							onClick={() => {
								fullPayment();
							}}
							loading={isLoadingFullPayment}
							className="ml-2"
						>
							<span style={{ textTransform: "capitalize" }}>
								Butuh pembayaran penuh
							</span>
						</Button>
					) : null}

					<AccurateChip value={!!data?.data?.accurateQuotationId} />
				</div>
				{/* Button Link to Accurate */}
				{!data?.data.accurateQuotationId &&
				data?.data.status == "DRAFT" &&
				!isAccurateDisabled ? (
					<LinkQuotation
						accurateData={
							data?.data?.QuotationRequest?.Project?.Customer
								?.accurateData
						}
						customerId={
							data?.data?.QuotationRequest?.Project?.Customer?.id
						}
						shippingAddress={
							data?.data.QuotationRequest?.Project
								?.ShippingAddress
						}
						batchingPlant={
							data?.data?.QuotationRequest?.BatchingPlant
						}
						requestedProducts={
							data?.data?.QuotationRequest?.RequestedProducts
						}
						projectSelected={
							data.data?.QuotationRequest?.Project?.id
						}
						refetch={refetch}
					></LinkQuotation>
				) : null}
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						flex: 1
					}}
				>
					{data?.data?.status !== "DRAFT" &&
					data?.data?.status !== "CANCELLED" ? (
						<ApproveQuotation
							customerType={
								data?.data?.QuotationRequest?.Project?.Customer
									?.type
							}
							customerId={
								data?.data?.QuotationRequest?.Project?.Customer
									?.id as string
							}
							accurateData={
								data?.data?.QuotationRequest?.Project?.Customer
									?.accurateData
							}
							refetch={refetch}
							requestedProducts={
								data?.data?.QuotationRequest?.RequestedProducts
							}
							batchingPlant={
								data?.data?.QuotationRequest?.BatchingPlant
							}
						/>
					) : null}{" "}
					{data?.data?.status !== "CANCELLED" && (
						<LoadingButton
							size="small"
							color="success"
							variant="contained"
							className="confirm-button approve-button"
							onClick={() => {
								pdfGenerate();
							}}
							loading={loadingPdf}
							sx={{
								color: "#fff",
								backgroundColor: "#2dce89",
								borderColor: "#2dce89",
								boxShadow:
									"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
								borderRadius: "0.375rem"
							}}
						>
							Generate SPH
						</LoadingButton>
					)}
					{/* <Button
						size="sm"
						color="primary"
						className="confirm-button approve-button"
						onClick={() => {
							localDialog.onOpen();
						}}
					>
						Upload SPH
					</Button> */}
				</div>
				{data?.data?.status !== "CANCELLED" && (
					<div className="d-flex align-items-center">
						<Button
							onClick={() => {
								setIsEditBP({ isOpen: true });
							}}
							size="sm"
							color="warning"
							className="confirm-button approve-button"
							style={{ marginLeft: "0.5rem" }}
						>
							Ubah
						</Button>

						{/* : null} */}
					</div>
				)}

				{data?.data?.status == "SUBMITTED" && (
					<div className="d-flex align-items-center">
						<Button
							onClick={() => {
								setIsReject({ isOpen: true });
							}}
							size="sm"
							color="danger"
							className="danger-button approve-button"
							style={{ marginLeft: "0.5rem" }}
						>
							Tolak
						</Button>
					</div>
				)}
			</div>
			<div className="d-flex flex-column flex-lg-row">
				<div
					className="d-flex flex-column mb-3"
					style={{ flex: 0.7, width: "75%" }}
				>
					<Card className="">
						<CardBody>
							{data?.data?.status !== "CANCELLED" && (
								<div className="d-flex justify-content-end">
									<Button
										size="sm"
										color="warning"
										className="confirm-button approve-button"
										onClick={() => {
											setIsEditProduct(true);
										}}
									>
										Ubah Harga Produk
									</Button>
								</div>
							)}

							<Table responsive hover striped>
								<thead>
									<tr>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											No
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Nama
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Slump
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Jenis
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Metode
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Element Casted
										</th>

										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Cementitious Composition
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Harga
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "center" }}
										>
											Kuantitas
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Jumlah
										</th>
									</tr>
								</thead>
								<tbody>
									{products?.map((item, idx) => (
										<tr key={idx}>
											<td style={{ textAlign: "left" }}>
												{idx + 1}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.name}{" "}
												{["B", "D"].includes(
													item?.specifications?.[
														"aggregate-combination"
													]
												)
													? " - Screening"
													: null}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.specifications?.[
													"slump-a"
												]
													? item?.specifications?.[
															"slump-a"
													  ] +
													  " ± " +
													  item?.specifications?.[
															"slump-b"
													  ]
													: item?.Product?.properties
															?.slump}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.specifications &&
												productType
													? productType.values[
															item
																?.specifications?.[
																"product-type"
															]
													  ]
													: null}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.withPump
													? "Dengan Pompa"
													: "Tanpa Pompa"}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.specifications &&
												castedElement
													? castedElement.values[
															item
																?.specifications?.[
																"casted-element"
															]
													  ]
													: null}
											</td>

											<td style={{ textAlign: "left" }}>
												{item?.specifications &&
												cementitiousComposition
													? cementitiousComposition
															.values[
															item
																?.specifications?.[
																"cementitious-component"
															]
													  ]
													: null}
											</td>
											<td style={{ textAlign: "right" }}>
												{getPriceFormatted(
													item?.offeringPrice,
													"Rp "
												)}
											</td>
											<td style={{ textAlign: "center" }}>
												{item?.quantity
													? formatComma(
															`${item.quantity} M³`
													  )
													: "0 M³"}
											</td>
											<td style={{ textAlign: "right" }}>
												{getPriceFormatted(
													item?.totalPrice,
													"Rp "
												)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</CardBody>
					</Card>
					<BCard
						className="mt-3"
						title="Ringkasan SPH"
						data={summary}
					/>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					<BCard title="Rincian Proyek" data={projectDetails} />
					<i className="bi bi-download"></i>
					<BCard className="mt-3" title="Dokumen" data={files} />
				</div>
			</div>

			{/* Upload PDF */}
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Upload SPH"
				onClose={() => {
					localDialog.onClose();
					setUploadFile(undefined);
				}}
			>
				{/* <BForm forms={forms} /> */}
				<div>
					<MuiFileInput
						onChange={(newFile) => {
							if (newFile && !Array.isArray(newFile)) {
								setUploadFile(newFile);
							} else {
								setUploadFile(undefined);
							}
						}}
						value={uploadFile}
						placeholder="Insert a file"
						multiple={false}
						label="SPH"
						inputProps={{
							accept: "application/pdf, image/png, image/jpeg"
						}}
					/>
					<Spacer size="large" />
					<div className="modal-custom-footer">
						<LoadingButton
							color="error"
							onClick={() => {
								setUploadFile(undefined);
								localDialog.onClose();
							}}
							loading={loadingUpload || loadingPut}
							disabled={loadingUpload || loadingPut}
						>
							<span style={{ textTransform: "capitalize" }}>
								Batal
							</span>
						</LoadingButton>
						<LoadingButton
							color="error"
							variant="contained"
							onClick={submitHandler}
							loading={loadingUpload || loadingPut}
							disabled={loadingUpload || loadingPut}
							sx={{ backgroundColor: "red", color: "white" }}
						>
							<span style={{ textTransform: "capitalize" }}>
								Upload{" "}
							</span>
						</LoadingButton>
					</div>
				</div>
			</LocalDialog>

			{/* Edit Batching Plant SPH */}
			<LocalDialog
				isOpen={isEditBP.isOpen}
				backdrop={"static"}
				header="Ubah Batching Plant"
				onClose={() => {
					setIsEditBP({ isOpen: false });
				}}
			>
				<BForm forms={forms} />
				<Spacer size="large" />
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						disabled={loadingEditBP}
						onClick={() => {
							setIsEditBP({ isOpen: false });
						}}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={submitBp}
						loading={loadingEditBP}
						disabled={loadingEditBP}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Ubah
						</span>
					</LoadingButton>
				</div>
			</LocalDialog>

			{/* Reject SPH */}
			<LocalDialog
				isOpen={isReject.isOpen}
				backdrop={"static"}
				header="Penolakan Surat Penawaran Harga"
				onClose={() => {
					setIsReject({ isOpen: false });
				}}
			>
				<span>Yakin ingin menolak Surat Penawaran Harga ini?</span>

				<div
					className="modal-custom-footer"
					style={{ marginTop: "1rem" }}
				>
					<LoadingButton
						color="error"
						disabled={isLoadingRejectQuo}
						onClick={() => {
							setIsReject({ isOpen: false });
						}}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={rejectQuotation}
						loading={isLoadingRejectQuo}
						disabled={isLoadingRejectQuo}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>Iya</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</div>
	);
};

export default QuotationDetail;
